import React, { Fragment, useEffect } from 'react'

import { isAuthorized } from 'auth/AuthorizeService'
import { roles } from 'auth/roles'
import useAuthorize from 'auth/useAuthorize'
import { AppHeader } from 'components/AppHeader'
import MainMenu from 'components/MainMenu'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { routes } from '../../routes'
import { languageSelector } from '../../services/account/slice'
import { get } from 'services/channelSettings/slice'

export const AppLayout = props => {
  const navigate = useNavigate()
  const authorize = useAuthorize(routes.helpManual.roles)
  const location = useLocation()
  const language = useSelector(languageSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    const userRoles = authorize?.userRoles
    if (location.pathname === routes.root.path) {
      if (isAuthorized(userRoles, [roles.REFERENT, roles.SENDER, roles.CONTROLLER, roles.COORDINATOR])) {
        navigate(routes.dashboard.path)
      } else if (isAuthorized(userRoles, [roles.AUDITOR])) navigate(routes.folder.path)
      else if (isAuthorized(userRoles, [roles.ADMIN])) navigate(routes.user.path)
      else if (isAuthorized(userRoles, [roles.SUPERADMIN])) navigate(routes.tenant.path)
      else if (isAuthorized(userRoles, [roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES])) navigate(routes.audit.path)
    }
  }, [authorize?.userRoles, location.pathname, navigate])

  useEffect(() => {
    dispatch(get())
  }, [dispatch])

  return (
    <Fragment>
      <AppHeader />
      <div className="flex-grow-1 d-flex" id="app_container">
        <MainMenu {...props} />
        <main className="container-fluid border" style={{ margin: '0px 15px 15px 15px' }}>
          <div className="row">
            <div className="col-sm-12">
              <Outlet context={{ userRoles: authorize?.userRoles, language }} key={localStorage.getItem('companyId')} />
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  )
}
