import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as api from './api'
import { addToast } from 'services/toast/slice'

export const get = createAsyncThunk('channelSettings/GET', async (payload, thunkAPI) => {
  const result = await api.get()

  if (result.error) return thunkAPI.rejectWithValue(result.error)
  return result.data
})

export const update = createAsyncThunk('channelSettings/UPDATE', async (payload, thunkAPI) => {
  const { data, error } = await api.update(payload)
  if (error) {
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.dispatch(addToast({ type: 'success', message: 'settingAreSaved' }))
    return data
  }
})

export const slice = createSlice({
  name: 'channelSettings',
  initialState: {
    model: null,
    isModelLoading: false,
    error: null,
    isUpdated: false,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    clearMessages: state => {
      state.isUpdated = false
    },
  },
  extraReducers: builder => [
    builder
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })
      .addCase(update.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(update.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
        state.isUpdated = true
      })
      .addCase(update.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      }),
  ],
})

export const { clearError, clearMessages } = slice.actions

export const modelSelector = state => state.channelSettings.model
export const isModelLoadingSelector = state => state.channelSettings.isModelLoading
export const errorSelector = state => state.channelSettings.error
export const isUpdatedSelector = state => state.channelSettings.isUpdated

export const reducer = slice.reducer
