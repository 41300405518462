import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import { isAuthorized } from 'auth/AuthorizeService'
import { routes } from 'routes'

import { userCompaniesSelector, modelSelector as userSelector } from 'services/auth/slice'
import { get } from 'services/settings/slice'

import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { get as getChannelSettings } from 'services/channelSettings/slice'
import './styles.css'
//import styles from './styles.module.css'

export const CompanySelect = () => {
  const navigate = useNavigate()
  const inputRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [companies, setCompanies] = useState([])
  const [searchTopCompany, setSearchTopCompany] = useState(null)

  const [companyInputValue, setCompanyInputValue] = useState('')

  const dispatch = useDispatch()
  const userCompanies = useSelector(userCompaniesSelector)
  const user = useSelector(userSelector)

  const companyId = localStorage.getItem('companyId')

  const toggleMenu = () => setMenuOpen(!menuOpen)

  const onKeyDown = e => {
    if (e.key === 'Escape') {
      setMenuOpen(false)
    }
    if (e.key === 'Enter') {
      const firstCompany = companies[0]
      firstCompany && changeCompany(firstCompany.companyId)
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      const target = e.target.nextSibling.firstChild
      target && target.focus()
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      const target = e.target.nextSibling.lastChild
      target && target.focus()
    }
  }

  const getCurrentUserCompanies = userCompanies => {
    const companies =
      userCompanies &&
      userCompanies.map(c => ({
        companyName: c.companyName,
        companyId: c.companyId,
      }))
    return companies
  }

  useEffect(() => {
    const companies = getCurrentUserCompanies(userCompanies)
    setCompanies(companies)
  }, [userCompanies])

  useEffect(() => {
    if (menuOpen) {
      const target = inputRef.current
      target && target.focus()
    }
  }, [menuOpen])

  useEffect(() => {
    if (!companies?.length) return

    if (searchTopCompany) {
      const oldTarget = searchTopCompany
      if (oldTarget) oldTarget.style.backgroundColor = 'white'
    }

    if (companyInputValue === '') return
    const target = inputRef.current.nextSibling.firstChild
    target && setSearchTopCompany(target)

    target.style.backgroundColor = '#e9ecef'
  }, [companies, companyInputValue, searchTopCompany])

  const changeCompany = companyId => {
    localStorage.setItem('companyId', companyId)
    setCompanyInputValue('')
    const companies = getCurrentUserCompanies(userCompanies)
    setCompanies(companies)
    dispatch(get())
    dispatch(getChannelSettings())
    dispatch(getChannelSettings())

    const currentCompanyUserRoles = userCompanies.find(c => c.companyId === companyId)?.roles
    const subDirectory = window.location.pathname

    let pathRoles
    let path = false

    for (let e of Object.values(routes)) {
      if (e.path && e.path === subDirectory) {
        pathRoles = e.roles
        // path = subDirectory
        path = routes.dashboard.path
        break
      } else if (e.path && subDirectory.includes(e.path) && e.path.includes('/folder')) {
        pathRoles = e.roles
        path = '/folder'
        break
      }
    }

    if (isAuthorized(currentCompanyUserRoles, pathRoles)) {
      navigate(path)
    } else {
      navigate('/')
    }
  }

  const handleCompanySearch = e => {
    const search = e.target.value
    const companies =
      userCompanies &&
      userCompanies
        .filter(c => c.companyName.toLowerCase().includes(search.toLowerCase()))
        .map(c => ({
          companyName: c.companyName,
          companyId: c.companyId,
        }))
    setCompanies(companies)
    setCompanyInputValue(search)
  }

  return userCompanies && userCompanies.length > 1 && user ? (
    <Dropdown isOpen={menuOpen} nav inNavbar toggle={toggleMenu}>
      <DropdownToggle nav caret>
        <i className="fas fa-light fa-building pr-2"></i>
        {userCompanies.find(c => c.companyId == companyId)?.companyName}
      </DropdownToggle>
      <DropdownMenu right>
        {userCompanies.length > 10 && (
          <input
            className="company-search-bar form-control"
            onChange={handleCompanySearch}
            onKeyDown={e => onKeyDown(e)}
            value={companyInputValue}
            ref={inputRef}
          />
        )}
        <div className="company-list">
          {companies.map(c => (
            <DropdownItem
              title={c.companyName}
              key={c.companyId}
              onClick={() => {
                toggleMenu()
                changeCompany(c.companyId)
              }}
            >
              {c.companyName.substring(0, 23).concat(c.companyName.length > 23 ? '...' : '')}
            </DropdownItem>
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  ) : userCompanies && userCompanies.length === 1 && user ? (
    <div className="p-2">
      <i className="fas fa-light fa-building pr-2"></i>
      {userCompanies[0]?.companyName}
    </div>
  ) : null
}
