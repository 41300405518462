import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, clearLogo } from 'services/settings/slice'
import { get as getChannelSettings } from 'services/channelSettings/slice'
import { routes } from 'routes'
import * as api from './api'

export const login = createAsyncThunk('auth/LOGIN', async (payload, thunkAPI) => {
  const { username, password, navigate, returnUrl } = payload
  const { data, error } = await api.login(username, password)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    const companyId = localStorage.getItem('companyId')
    !companyId && localStorage.setItem('companyId', data.userAuthorizationInfo.authInfo[0].companyId)
    localStorage.setItem('showActionTitle', localStorage.getItem('showActionTitle') === 'false' ? false : true)
    thunkAPI.dispatch(loginSuccess({ navigate, returnUrl }))
    thunkAPI.dispatch(slice.actions.resetIsLoggingIn())
    thunkAPI.dispatch(get())
    return data
  }
})

const loginSuccess = createAsyncThunk('auth/LOGIN_SUCCESSS', async ({ navigate, returnUrl }) => {
  navigate(returnUrl || routes.root.path)
})

export const logout = createAsyncThunk('auth/LOGOUT', async (navigate, thunkAPI) => {
  const { data, error } = await api.logout()
  thunkAPI.dispatch(clearLogo())
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(logoutSuccess(navigate))
    return data
  }
})

const logoutSuccess = createAsyncThunk('auth/LOGOUT_SUCCESSS', async navigate => {
  navigate(routes.login.path)
})

export const getUser = createAsyncThunk('auth/GET_USER', async (payload, thunkAPI) => {
  const { data, error } = await api.getUser()
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const slice = createSlice({
  name: 'auth',
  initialState: {
    model: null,
    isModelLoading: null,
    isAuthenticated: false,
    isSessionChecked: false,
    userCompanies: [],
    error: null,
    logInError: null,
    isLoggingIn: false,
    isLoggingOut: false,
  },
  reducers: {
    resetIsLoggingIn: state => {
      state.isLoggingIn = false
    },
    clearError: state => {
      state.error = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(login.pending, state => {
        state.isLoggingOut = false
        state.isModelLoading = true
        state.logInError = null
        state.isLoggingIn = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.isAuthenticated = true
        state.model = action.payload
        state.userCompanies = action.payload?.userAuthorizationInfo?.authInfo?.sort((a, b) =>
          a.companyName.localeCompare(b.companyName),
        )
      })
      .addCase(login.rejected, (state, action) => {
        state.isModelLoading = false
        state.isModelLoading = true
        state.logInError = action.payload
      })

      .addCase(getUser.pending, state => {
        state.isModelLoading = false
        state.error = null
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.isSessionChecked = true
        state.isAuthenticated = true
        state.model = action.payload
        state.userCompanies = action.payload?.userAuthorizationInfo?.authInfo.sort((a, b) =>
          a.companyName.localeCompare(b.companyName),
        )
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isModelLoading = false
        // state.isSessionChecked = true
        state.isAuthenticated = false
        state.error = action.error
      })

      .addCase(logout.pending, state => {
        state.isLoggingOut = true
        state.isModelLoading = true
        state.error = null
      })
      .addCase(logout.fulfilled, state => {
        state.isModelLoading = false
        state.isAuthenticated = false
        state.isSessionChecked = false
        state.model = null
      })
      .addCase(logout.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      }),
  ],
})

export const { clearError } = slice.actions

export const modelSelector = state => state.auth.model
export const isModelLoadingSelector = state => state.auth.isModelLoading
export const isAuthenticatedSelector = state => state.auth.isAuthenticated
export const isSessionCheckedSelector = state => state.auth.isSessionChecked
export const errorSelector = state => state.auth.error
export const logInErrorSelector = state => state.auth.logInError
export const userCompaniesSelector = state => state.auth.userCompanies
export const isLoggingInSelector = state => state.auth.isLoggingIn
export const isLoggingOutSelector = state => state.auth.isLoggingOut

export const reducer = slice.reducer
